'use client';

import PropTypes from 'prop-types';
import { useState } from 'react';
// hooks
import useAuth from 'hooks/useAuth';
// components
import Loading from 'components/loading/Loading';
import { useLocation, Navigate } from 'react-router-dom';
import Login from 'pages/ingresar/Login';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, user, logout } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  let routesMap = {
    authenticated: '/plataforma/estudiantes',
    parent: '/plataforma/acudientes',
    teacher: '/plataforma/profesores',
    admin: '/plataforma/administrador',
    secretary_admin: '/plataforma/administrador',
    supervisor_admin: '/plataforma/administrador',
    marketing: '/plataforma/administrador',
    content_admin: '/plataforma/contenido',
    affiliate: '/plataforma/afiliados',
    adviser: '/plataforma/asesoras',
    community: '/plataforma/administrador',
    coordinator: '/plataforma/administrador',
    school: '/plataforma/administrador',
  };

  if (!isInitialized) {
    return <Loading />;
  }
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  if (!pathname.includes(routesMap[user.role.type])) {
    return <Navigate to={routesMap[user.role.type]} />;
  }

  return <>{children}</>;
}
