import { Box, Flex, Button, Select, Textarea, Spinner, Input, Checkbox, useToast } from '@chakra-ui/react';

import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import dayjs from 'dayjs';

import DatePicker from 'components/datePicker/DatePicker';
import globals from 'styles/globals.module.css';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';
import Month from './month';

export default function FormPagos(props) {
  let params = useParams();
  const { mutate, paymentsData, advisers, setIsOpen } = props;
  const [payments, setPayments] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [months, setMonths] = React.useState([]);
  const [selectedMonths, setSelectedMonths] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const [subjectEnrollments, setSubjectsEnrollments] = React.useState([]);
  const [otherPayments, setOtherPayments] = React.useState([]);
  const [concept, setConcept] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [currentProgram, setCurrentProgram] = React.useState('');
  const [currentProgramType, setCurrentProgramType] = React.useState({});
  const [currentEnrollment, setCurrentEnrollment] = React.useState();
  const [fetchingEnrollment, setFetchingEnrollment] = React.useState(true);
  const [currencies, setCurrencies] = React.useState([]);
  const [affiliates, setAffiliates] = React.useState([]);
  const { user } = useAuth();

  const id_est = params.id;

  React.useEffect(() => {
    const getStudentPrograms = async () => {
      const response = await xhr(`/students/${id_est}/programs`);
      const currenciesResponse = await xhr(`/currencies`);
      const getAffiliates = await xhr(`/affiliates?pagination[limit]=-1&populate=user`);
      setAffiliates(
        getAffiliates.data.data.map((el) => ({
          value: el.id,
          label: `${el.user?.first_name} ${el.user?.last_name}`,
        }))
      );
      setCurrencies(
        currenciesResponse.data.data.map((el) => ({
          value: el.id,
          label: el.name,
        }))
      );

      setPrograms(response.data);
    };

    getStudentPrograms();
  }, []);
  React.useEffect(() => {
    const getSubjectEnrollments = async () => {
      const response = await xhr(
        `/subject-enrollments?filters[student][id][$eq]=${id_est}&filters[subject][${currentProgram.program_type.type.replace(
          '-',
          '_'
        )}][id][$eq]=${currentProgram.id}&pagination[limit]=-1&populate=deep,2`
      );
      const filterIsTrial = response.data.data.filter((e) => e.is_trial !== true);
      setSubjectsEnrollments(filterIsTrial.map((el) => el.subject.id));
    };
    if (currentProgram) {
      getSubjectEnrollments();
    }
  }, [currentProgram]);

  const [productsFetching, setProductsFetching] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [monthStudent, setMonthStudent] = React.useState([]);
  const [isPromo, setIsPromo] = React.useState(false);
  const [dateFilter, setDateFilter] = React.useState(null);
  const [amount, setAmount] = React.useState(0);
  const [conceptList, setConceptList] = React.useState([]);
  const [file, setFile] = React.useState(null);

  const refModal = React.useRef();
  const inputEl = React.useRef(null);
  const toasts = useToast();

  const handleDateChange = (date) => {
    setDateFilter(date);
  };

  const handleConcept = (event) => {
    setConcept(event);
    setAmount(0);
  };

  const handleProgram = async (value, currency) => {
    try {
      setProductsFetching('subjects');
      const program = programs.find((el) => el.id === Number(value));
      const response = await xhr(`/subjects?filters[${program.program_type.type}][id][$eq]=${program.id}`);
      setSubjects(response.data.data);
      setCurrentProgram(program);
    } catch (error) {
    } finally {
      setProductsFetching('');
    }
  };

  console.log(currencies);

  const handleProgramType = async (value, currency) => {
    try {
      setProductsFetching('other_payments');

      const programType = props.programTypes.find((el) => Number(el.id) === Number(value));

      let response;

      if (programType.type === 'course') {
        response = await xhr(`/other-payments?filters[course][id][$eq]=${currentProgram.id}`);
      } else {
        response = await xhr(`/other-payments?filters[program_type][id][$eq]=${value}`);
      }

      const otherPaymentsWithPrice = await Promise.all(
        response.data.data.map(async (el) => {
          const priceResponse = await xhr(
            `/other-payment-prices?filters[other_payment][id][$eq]=${el.id}&filters[currency][id][$eq]=${currency}`
          );

          return { ...el, price: priceResponse.data.data[0] };
        })
      );

      setOtherPayments(otherPaymentsWithPrice);
      setCurrentProgramType(programType);
    } catch (error) {
    } finally {
      setProductsFetching('');
    }
  };

  useEffect(() => {
    (async () => {
      setFetchingEnrollment(true);
      if (currentProgram && currentProgramType?.id) {
        const currentEnrollment = await xhr(
          `/${currentProgramType.type.replace('_', '-')}-enrollments?filters[student][id][$eq]=${id_est}&filters[${
            currentProgramType.type
          }][id][$eq]=${currentProgram.id}&filters[${currentProgramType.type}][program_type][id][$eq]=${
            currentProgramType.id
          }&populate=${currentProgramType.type}&populate=semester&sort[0]=updatedAt:desc`
        );

        setCurrentEnrollment(currentEnrollment.data.data.length > 0 ? currentEnrollment.data.data[0] : false);
        setFetchingEnrollment(false);
      }
    })();
  }, [currentProgram, currentProgramType]);

  const handleCheck = (item, checked) => {
    let newConceptList;

    if (checked) {
      newConceptList = [...conceptList, item];
    } else {
      newConceptList = conceptList.filter((el) => el.id !== item.id);
    }

    const value = newConceptList.reduce((acc, current) => {
      return Number(acc) + Number(current.price ? current.price.value : 0);
    }, 0);

    setAmount(value);
    setConceptList(newConceptList);
  };

  const handleFileInput = (event) => {
    let currrentFile = event.target.files[0];
    if (!currrentFile) return false;

    setFile(currrentFile);
  };

  const handlePayment = async (values, action) => {
    try {
      if (!dateFilter || !values.method || !values.currency || !concept || !values.state || !amount)
        return window.alert('Debes completar los campos requeridos *');

      // if (currentProgram.program_type.type !== 'course' && months.length === 0)
      //   return window.alert('Debes seleccionar al menos 1 mes *')

      if (concept) {
        if (concept === 'subjects' && !conceptList.length) return window.alert('Debes agregar una materia');
        if (currentProgram.slug !== 'academia-de-ingles') {
          if (concept === 'other_payments' && !conceptList.length) return window.alert('Debes agregar un otro pago');
        }
      }

      setLoading(true);

      let data = {
        student: id_est,
        status: 'approved',
        amount,
        promo: isPromo,
        payment_date: dayjs(dateFilter).format('YYYY-MM-DD hh:mm'),
        type: concept,
        method: values.method,
        currency: values.currency,
        description,
        program_type: currentProgram.program_type.id,
        [currentProgram.program_type.type.replace('-', '_')]: currentProgram.id,
        state: values.state,
        school: user?.school?.id || null,
      };

      if (values.adviser) {
        data = { ...data, adviser: values.adviser };
      }
      if (values.affiliate) {
        data = { ...data, affiliate: values.affiliate };
      }

      let conceptListIds = conceptList.map((el) => el.id);

      if (concept === 'subjects') {
        data[currentProgram.program_type.type] = currentProgram.id;
        data.subjects = conceptListIds;
      }

      if (concept === 'other_payments') {
        data.other_payments = conceptListIds;
      }

      const response = await xhr.post(`/payments/`, { data: data });

      if (monthStudent.length === 0) {
        const responseMonths = await xhr.post(`/month-students/`, {
          data: {
            student: id_est,
            [`${currentProgramType.type}_enrollment`]: currentEnrollment.id,
            months: months.map((el) => el.id),
            on_day: checked,
          },
        });
      } else {
        const responseMonths = await xhr.put(
          `/month-students/${monthStudent?.length > 0 ? monthStudent[0].id : monthStudent.id}`,
          {
            data: {
              months: months.map((el) => el.id),
              on_day: checked,
            },
          }
        );
      }

      const responsePopulate = await xhr(`/payments/${response.data.data.id}?populate=deep,3`);

      setPayments([responsePopulate.data.data, ...payments]);

      if (file) {
        const formData = new FormData();

        formData.append('files', file);
        formData.append('ref', 'api::payment.payment');
        formData.append('refId', responsePopulate.data.data.id);
        formData.append('field', 'image');

        const uploadResponse = await xhr('/upload', {
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      }
      mutate([responsePopulate.data.data, ...paymentsData]);

      action.resetForm();

      inputEl.current.value = '';
      setAmount(0);
      setDateFilter(null);
      setDescription('');
      setSubjects([]);
      setIsOpen(false);
      toasts({
        title: 'El pago se creo correctamente!',
        accent: 'bottom',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box width="100%" marginTop="15px" marginBottom="md">
      <Formik
        initialValues={{
          amount: '',
          method: '',
          state: '',
          affiliate: '',
          adviser: '',
          currency: '',
          description: '',
          concept: '',
        }}
        onSubmit={handlePayment}
      >
        {(formData) => {
          const conceptListIds = conceptList.map((el) => el.id);

          return (
            <Form>
              <Box marginY="5px">Fecha Pago *</Box>
              <DatePicker date={dateFilter} placeholder="Fecha del pago" onChange={handleDateChange} />
              <Box marginY="5px">Medio de Pago *</Box>
              <Field
                name="method"
                as="select"
                placeholder="Medio de Pago"
                color="black"
                className={`${globals.input} ${globals.nombres}`}
              >
                <option value=""> Seleccione </option>
                <option value="payu"> PayU </option>
                <option value="bancolombia"> Bancolombia </option>
                <option value="efecty"> Efecty </option>
                <option value="wompi"> Wompi </option>
                <option value="sede_bucaramanga"> Sede Bucaramanga </option>
                <option value="sede_turbaco"> Sede Turbaco </option>
                <option value="sede_arjona"> Sede Arjona </option>
                <option value="western_union"> Western Union </option>
                <option value="stripe"> Stripe </option>
                <option value="paypal"> Paypal </option>
                <option value="addi"> Addi </option>
                <option value="bitcoins"> Bitcoins </option>
              </Field>
              <ErrorMessage name={'method'}>
                {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
              </ErrorMessage>

              <Box marginY="5px">Estado *</Box>
              <Field
                name="state"
                as="select"
                placeholder="Asesor"
                color="black"
                className={`${globals.input} ${globals.nombres}`}
              >
                <option value=""> Seleccione </option>
                {user?.school ? (
                  ''
                ) : (
                  <>
                    <option value="admisiones"> Admisiones </option>
                    <option value="cobranza"> Cobranza </option>
                  </>
                )}

                <option value="recurrente"> Recurrente </option>
              </Field>
              <ErrorMessage name={'state'}>
                {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
              </ErrorMessage>

              {formData.values.state === 'admisiones' && (
                <>
                  <Box marginY="5px">Asesor</Box>
                  <Field
                    name="adviser"
                    as="select"
                    placeholder="Asesor"
                    color="black"
                    className={`${globals.input} ${globals.nombres}`}
                  >
                    <option value=""> Seleccione </option>
                    {advisers.map((el) => (
                      <option value={el.value}> {el.label} </option>
                    ))}
                  </Field>

                  <Box marginY="5px">Afiliado</Box>
                  <Field
                    name="affiliate"
                    as="select"
                    placeholder="Afiliado"
                    color="black"
                    className={`${globals.input} ${globals.nombres}`}
                  >
                    <option value=""> Seleccione </option>
                    {affiliates.map((el) => (
                      <option value={el.value}> {el.label} </option>
                    ))}
                  </Field>
                </>
              )}

              <Box marginY="5px">Moneda *</Box>
              <Field
                name="currency"
                as="select"
                placeholder="Moneda"
                color="black"
                className={`${globals.input} ${globals.nombres}`}
              >
                <option value={''}>Seleccione</option>
                {currencies.map((el) => {
                  return (
                    <option key={el.label} value={el.value}>
                      {el.label}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage name={'currency'}>
                {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
              </ErrorMessage>

              {formData.values.currency && (
                <>
                  <Box marginY="5px">Concepto *</Box>
                  <Select
                    name="concept"
                    color="black"
                    className={`${globals.input} ${globals.nombres}`}
                    onChange={(event) => handleConcept(event.target.value)}
                  >
                    <option value={''}>Seleccione</option>
                    <option value={'other_payments'}>Pagos</option>
                    <option value={'others'}>Otros</option>
                  </Select>
                  <ErrorMessage name={'concept'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>

                  {concept && (
                    <>
                      <Box marginY="5px">Programa *</Box>
                      <Select onChange={(e) => handleProgram(e.target.value, formData.values.currency)}>
                        <option value={''}> Seleccione</option>
                        {programs.map((el) => (
                          <option value={el.id}> {el.name}</option>
                        ))}
                      </Select>
                      {/* <Field
                        name="program"
                        placeholder="Programa"
                        color="black"
                        as="select"
                        className={`${globals.input} ${globals.nombres}`}
                        onChange={(event) => }
                      >
                       
                      </Field> */}
                    </>
                  )}

                  {concept === 'subjects' && formData.values.program && (
                    <>
                      <Box marginBottom="md" border="1px solid" borderColor="white900">
                        <Box
                          backgroundColor="primary100"
                          color="primary700"
                          fontWeight="500"
                          padding="8"
                          paddingX="15px"
                        >
                          ¿Qué materias paga el estudiante?
                        </Box>

                        {productsFetching === 'subjects' ? (
                          <Flex justifyContent="center" paddingY="lg">
                            <Spinner />
                          </Flex>
                        ) : (
                          <Box display="grid" gridTemplateColumns="1fr 1fr" padding="15px" gridGap="12px">
                            {subjects.map((el) => {
                              const isEnrolled = subjectEnrollments.includes(el.id);

                              return (
                                <Flex>
                                  <Checkbox
                                    checked={isEnrolled || conceptListIds.includes(el.id)}
                                    isDisabled={isEnrolled}
                                    textDecoration={isEnrolled && 'line-through'}
                                    cursor={isEnrolled ? 'not-allowed' : 'pointer'}
                                    label={el.name}
                                    onChange={(event) => handleCheck(el, event.target.checked)}
                                  />
                                </Flex>
                              );
                            })}

                            {!subjects.length && <Box textAlign="center">No se encontraron materias</Box>}
                          </Box>
                        )}
                      </Box>
                    </>
                  )}

                  {concept === 'other_payments' && (
                    <>
                      <Box marginY="5px">Tipo de Programa *</Box>
                      <Select
                        name="program_type"
                        variant="filled"
                        value={formData.values.program_type}
                        backgroundColor="transparent"
                        border="1px solid #e2dcdc!important"
                        onChange={(event) => handleProgramType(event.target.value, formData.values.currency)}
                      >
                        <option value={''}>Seleccione</option>
                        {props.programTypes?.map((el) => (
                          <option value={el.id}>{el.name}</option>
                        ))}
                      </Select>
                    </>
                  )}

                  {concept === 'other_payments' && Object.keys(currentProgramType).length > 0 && (
                    <>
                      <Box
                        marginBottom="20px"
                        marginTop="10px"
                        border="1px solid #e2e8f0"
                        borderColor="white900"
                        borderTopRadius="5px"
                      >
                        <Box
                          color="purple.700"
                          fontWeight="500"
                          paddingY="8px"
                          paddingX="15px"
                          backgroundColor="purple.200"
                          borderTopRadius="5px"
                        >
                          ¿Qué otros pagos cancela el estudiante?
                        </Box>

                        {productsFetching === 'other_payments' ? (
                          <Flex justifyContent="center" paddingY="lg">
                            <Spinner />
                          </Flex>
                        ) : (
                          <Box display="grid" gridTemplateColumns="1fr 1fr" padding="15px" gridGap="12px">
                            {otherPayments.map((el) => {
                              return (
                                <Flex>
                                  <Checkbox
                                    defaultChecked={conceptListIds.includes(el.id) ? true : false}
                                    onChange={(event) => handleCheck(el, event.target.checked)}
                                  >
                                    {el.name}
                                  </Checkbox>
                                </Flex>
                              );
                            })}

                            {!otherPayments.length && <Box textAlign="center">No se encontraron otros pagos</Box>}
                          </Box>
                        )}
                      </Box>
                      <Month
                        idStudent={id_est}
                        type={currentProgramType.type}
                        currentEnrollment={currentEnrollment}
                        fetching={fetchingEnrollment}
                        setMonths={setMonths}
                        months={months}
                        selectedMonths={selectedMonths}
                        setSelectedMonths={setSelectedMonths}
                        setMonthStudent={setMonthStudent}
                        setChecked={setChecked}
                        checked={checked}
                      />
                    </>
                  )}

                  <Box for="amount">Monto *</Box>
                  <Field
                    value={amount}
                    marginTop="20px"
                    type="number"
                    label="Valor"
                    name="amount"
                    onChange={(event) => setAmount(event.target.value)}
                    className={`${globals.input} ${globals.nombres}`}
                  />
                </>
              )}

              <Checkbox
                checked={isPromo}
                onChange={(e) => setIsPromo(e.target.checked)}
                _focus={{ boxShadow: 'transparent' }}
                my="10px"
              >
                Aplica Promoción
              </Checkbox>

              <br />

              <Box for="description">Descripción</Box>

              <Textarea onChange={(event) => setDescription(event.target.value)} />

              <br />
              <Box for="amount">Imagen del Pago</Box>
              <Input type="file" ref={inputEl} onChange={handleFileInput} />
              <br />

              <Flex alignItems="center">
                <Button
                  type="submit"
                  colorScheme="messenger"
                  backgroundColor="#574feb"
                  marginY="15px"
                  width="140px"
                  isLoading={loading}
                  isDisabled={loading}
                >
                  Guardar
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
