import {
  Box,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Button,
  Input,
  Grid,
  GridItem,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React from 'react';
import useSWR from 'swr';
import getStudentsAdmin from 'api/administrador/searchStudent';
import { FaArrowRight } from 'react-icons/fa';
import handleSearch from 'api/administrador/handleSearch';
import Loading from 'components/loading/Loading';
import useAuth from 'hooks/useAuth';

export default function SearchStudentGlobal(props) {
  const { route, counts, teacher } = props;
  const [students, setStudents] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [id, setId] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [cellphone, setCellphone] = React.useState('');
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const { user } = useAuth();

  const { data, error, isLoading } = useSWR({ type: 'getStudents', user: user }, getStudentsAdmin, {
    revalidateOnFocus: false,
  });

  console.log(error);
  React.useEffect(() => {
    setStudents(data);
  }, [data]);

  if (!data) return <Loading />;

  return (
    <Box>
      <Box
        backgroundColor="white"
        borderRadius="10px"
        border="1px solid #9e46d8"
        marginRight="10px"
        paddingBottom="10px"
      >
        <Text
          color="white"
          backgroundColor="#574feb"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
          paddingLeft="10px"
          fontSize="20px"
          paddingTop="10px"
          paddingBottom="10px"
          fontWeight="bold"
        >
          Consultar Estudiante
        </Text>

        <Box textAlign="center" marginX="15px" marginY="10px">
          <Grid templateColumns="repeat(3, 1fr)" alignY="center" gap={3}>
            <GridItem colSpan={1}>
              <Input
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch({
                      id,
                      email,
                      cellphone,
                      firstName,
                      lastName,
                      setStudents: setStudents,
                      setFetching: setFetching,
                      user: user,
                    });
                  }
                }}
                placeholder="ID"
                onChange={(event) => {
                  setId(event.target.value);
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Input
                placeholder="Correo"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Input
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch({
                      id,
                      email,
                      cellphone,
                      firstName,
                      lastName,
                      setStudents: setStudents,
                      setFetching: setFetching,
                      user: user,
                    });
                  }
                }}
                placeholder="Celular"
                onChange={(event) => {
                  setCellphone(event.target.value);
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Input
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch({
                      id,
                      email,
                      cellphone,
                      firstName,
                      lastName,
                      setStudents: setStudents,
                      setFetching: setFetching,
                      user: user,
                    });
                  }
                }}
                placeholder="Nombres"
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Input
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch({
                      id,
                      email,
                      cellphone,
                      firstName,
                      lastName,
                      setStudents: setStudents,
                      setFetching: setFetching,
                      user: user,
                    });
                  }
                }}
                placeholder="Apellidos"
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </GridItem>

            <GridItem colSpan={1}>
              <Button
                width="100%"
                backgroundColor="#574feb"
                colorScheme="messenger"
                onClick={() =>
                  handleSearch({
                    id,
                    email,
                    cellphone,
                    firstName,
                    lastName,
                    setStudents: setStudents,
                    setFetching: setFetching,
                    user: user,
                  })
                }
                isLoading={fetching ? true : teacher ? (!counts ? true : false) : false}
                disabled={fetching ? true : teacher ? (!counts ? true : false) : false}
              >
                Buscar
              </Button>
            </GridItem>
          </Grid>
        </Box>

        {isLoading ? (
          <Flex alignX="center" marginY="lg">
            <Spinner size="large" />
          </Flex>
        ) : students?.length === 0 ? (
          <Box p="20px">No hay estudiantes para tu criterio de busqueda</Box>
        ) : (
          <TableContainer marginX="15px" border="1px solid #f7f7fa" borderRadius="5px">
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th textAlign="left">ID</Th>
                  <Th>Nombres</Th>
                  <Th>Email</Th>
                  <Th>Celular</Th>
                  <Th>Ver</Th>
                </Tr>
              </Thead>
              <Tbody>
                {students?.map((el) => {
                  if (!el.user) return null;

                  return (
                    <Tr key={el.id}>
                      <Td textAlign="left">{el.id}</Td>
                      <Td>
                        {el.user.first_name} {el.user.last_name}
                      </Td>
                      <Td>{el.user.email}</Td>
                      <Td>{el.user.cellphone}</Td>
                      <Td>
                        <Link to={`/plataforma/${route}/ver-estudiante/${el.id}`}>
                          <Button colorScheme="messenger" background="#574feb">
                            <FaArrowRight size="10px" />
                          </Button>
                        </Link>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        {!students && (
          <Box textAlign="center" paddingY="md">
            No se encontraron resultados
          </Box>
        )}
      </Box>

      <style>{`
        .bb-PaginationSelect {
          width: 100px;
        }
      `}</style>
    </Box>
  );
}
