import React from 'react';

import { Box, Button, useToast, Select } from '@chakra-ui/react';
import xhr from 'xhr';
import useAuth from 'hooks/useAuth';

export default function UpdateGrade(props) {
  const {
    statusEnrollment,
    setStatusEnrollment,
    groupEnrollment,
    setGroupEnrollment,
    semesterEnrollment,
    setSemesterEnrollment,
    gradeEnrollment,
    currentEnrollment,
    setCurrentEnrollment,
    id_est,
    dataAll,
    enrollments,
    semester,
    mutate,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [loadingDelete, setLoadingDelete] = React.useState(false);
  const [classGroups, setClassGroups] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const toasts = useToast();
  const { user } = useAuth();
  React.useEffect(() => {
    const getGroups = async () => {
      try {
        let res;

        if (user?.school) {
          res = await xhr(
            `/class-groups?filters[grade][id][$eq]=${currentEnrollment.grade.id}&filters[school][id][$eq]=${user?.school?.id}&pagination[limit]=-1&populate=*`
          );
        } else {
          res = await xhr(
            `/class-groups?filters[grade][id][$eq]=${currentEnrollment.grade.id}&pagination[limit]=-1&populate=*`
          );
        }

        setClassGroups(res.data.data);
      } catch (error) {}
    };
    getGroups();
  }, [currentEnrollment.id]);

  React.useEffect(() => {
    const getSubjectsEnrollments = async () => {
      try {
        const res = await xhr(
          `/subject-enrollments?filters[student][id][$eq]=${id_est}&filters[subject][grade][id][$eq]=${currentEnrollment.grade.id}&pagination[limit]=-1&populate=*`
        );

        setSubjects(res.data.data);
      } catch (error) {}
    };
    getSubjectsEnrollments();
  }, [currentEnrollment.id]);

  const updateState = async () => {
    try {
      if (!statusEnrollment || !semesterEnrollment || !groupEnrollment) {
        return window.alert('Debes llenar todos los campos');
      }

      setLoading(true);

      const resUpdate = await xhr.put(`/grade-enrollments/${currentEnrollment.id}`, {
        data: {
          status: statusEnrollment,
          semester: semesterEnrollment,
        },
      });

      const classGroup = classGroups.find((el) => {
        return Number(el.id) === Number(groupEnrollment);
      });

      let studentGroup = {};
      if (classGroup && Number(groupEnrollment) !== currentEnrollment.class_group?.id) {
        if (currentEnrollment.student_group) {
          await xhr.delete(`/student-groups/${currentEnrollment.student_group.id}`);
        }

        const studentGroupResponse = await xhr.post('/student-groups', {
          data: {
            student: id_est,
            class_group: groupEnrollment,
          },
        });

        const resN = await xhr(`/student-groups/${studentGroupResponse.data.data.id}?populate=*`);

        studentGroup = resN.data.data;

        setCurrentEnrollment({
          ...currentEnrollment,
          class_group: studentGroup?.class_group,
          student_group: studentGroup,
        });
      }

      mutate(
        {
          ...dataAll,
          enrollments: enrollments.map((el) => {
            if (el.id === currentEnrollment.id) {
              return {
                ...el,
                ...resUpdate.data.data,
                class_group: studentGroup?.class_group,
                student_group: studentGroup,
              };
            }

            return el;
          }),
        },
        { revalidate: false }
      );

      toasts({
        title: 'El enrollment se actualizó correctamente!',
        accent: 'bottom',
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSubjectEnrollment = async (element) => {
    try {
      setLoadingDelete(true);

      const resUpdate = await xhr.delete(`/subject-enrollments/${element.id}`);

      setSubjects(subjects.filter((el) => el.id !== element.id));
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <>
      <Box>
        <Box>
          <Box fontWeight="bold" fontSize="20px">
            Modificar {currentEnrollment.grade?.name}
          </Box>
          <hr />

          <Select
            value={statusEnrollment}
            marginTop="20px"
            name="estado_enrollment"
            label="Estado"
            color="black"
            placeholder="Estado"
            onChange={(e) => setStatusEnrollment(e.target.value)}
          >
            <option value="registrado">registrado</option>
            <option value="estudiando">estudiando</option>
            <option value="promovido">promovido</option>
            <option value="graduado">graduado</option>
            <option value="retirado">retirado</option>
          </Select>

          <Select
            value={semesterEnrollment}
            marginTop="20px"
            name="semester_enrollment"
            label="Semestre"
            color="black"
            onChange={(e) => setSemesterEnrollment(e.target.value)}
          >
            {semester.map((el) => (
              <option value={el.value}>{el.label}</option>
            ))}
          </Select>
          <Box mt="10px">Grupos</Box>
          <Select
            value={groupEnrollment}
            marginTop="5px"
            name="group_enrollment"
            color="black"
            placeholder="Grupo"
            onChange={(e) => setGroupEnrollment(Number(e.target.value))}
            options={classGroups}
          >
            {classGroups.map((el) => (
              <option value={el.id}>{el.name}</option>
            ))}
          </Select>

          <Box my="10px" fontWeight={600}>
            Materias Matriculadas
          </Box>
          <Box>
            {subjects.map((el) => (
              <Box flex gap={7}>
                {el?.subject?.name}
                <Button
                  colorScheme="red"
                  size={'xs'}
                  onClick={() => deleteSubjectEnrollment(el)}
                  disabled={loadingDelete}
                  isLoading={loadingDelete}
                >
                  Eliminar
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
        <br />
        <Button
          type="submit"
          colorScheme="#574feb"
          backgroundColor="#574feb"
          marginTop="15px"
          width="100%"
          isLoading={loading}
          onClick={updateState}
        >
          Actualizar
        </Button>
      </Box>
    </>
  );
}
