import React from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Heading,
  Button,
  Box,
  Select,
  useToast,
  Textarea,
  Flex,
  Input,
} from '@chakra-ui/react';

import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSWRConfig } from 'swr';
import xhr from 'xhr';
import BasicModal from 'components/modal/Modal';
import { BiPencil, BiTrash } from 'react-icons/bi';
import useAuth from 'hooks/useAuth';

export default function HistoryPayments() {
  const [payments, setPayments] = React.useState([]);
  const [payment, setPayment] = React.useState({});
  const [paymentModify, setPaymentModify] = React.useState({});

  const { user } = useAuth();
  const [paymentId, setPaymentsId] = React.useState('');

  const [statusPayment, setStatusPayment] = React.useState('');
  const [idStudent, setIdStudent] = React.useState();
  const [valueText, setValueText] = React.useState('');

  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const { mutate } = useSWRConfig();

  const toast = useToast();

  React.useEffect(() => {
    const getFeedback = async () => {
      try {
        setLoading(true);
        if (user?.school?.id) {
          const res = await xhr(
            `/payments?filters[school][id][$eq]=${user?.school?.id}&filters[student][id][$gt]=0&populate=*&sort[0]=createdAt:desc`
          );
          setPayments(res.data.data);
        } else {
          const res = await xhr(`/payments?filters[student][id][$gt]=0&populate=*&sort[0]=createdAt:desc`);
          setPayments(res.data.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    getFeedback();
  }, []);

  const handleFilter = async () => {
    try {
      setFetching(true);
      if (user?.school?.id) {
        const res = await xhr(
          `/payments?filters[school][id][$eq]=${user?.school?.id}&filters[student]=${idStudent}&populate=*&sort[0]=createdAt:desc`
        );
        setPayments(res.data.data);
      } else {
        const res = await xhr(`/payments?filters[student]=${idStudent}&populate=*&sort[0]=createdAt:desc`);
        setPayments(res.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  const handleCleanFilter = async () => {
    try {
      setIdStudent('');
      setFetching(true);
      if (user?.school?.id) {
        const res = await xhr(
          `/payments?filters[school][id][$eq]=${user?.school?.id}&filters[student][id][$gt]=0&populate=*&sort[0]=createdAt:desc`
        );
        setPayments(res.data.data);
      } else {
        const res = await xhr(`/payments?filters[student][id][$gt]=0&populate=*&sort[0]=createdAt:desc`);
        setPayments(res.data.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };
  const handlePayment = async (values, action) => {
    try {
      setLoading(true);

      await xhr.post(`/history-payments`, {
        data: {
          status: 'modified',
          value: payment.amount,
          method: payment.method,
          state: payment.state,
          payment_date: payment.payment_date,
          student: payment.student.id,
          from_user: user.id,
          payment: payment.id,
          other_payments: payment.other_payments.map((el) => el.id),
          subjects: payment.subjects.map((el) => el.id),
          grade: payment.grade?.id,
          technical_career: payment.technical_career?.id,
          course: payment.course?.id,
          currency: payment.currency?.id,
          program_type: payment.program_type?.id,
          adviser: payment.adviser?.id,
          affiliate: payment.affiliate?.id,
        },
      });

      await xhr.put(`/payments/${payment.id}`, {
        data: {
          amount: paymentModify.amount,
          description: paymentModify.description,
          payment_date: paymentModify.payment_date,
          method: paymentModify.method,
        },
      });

      setPayments(
        payments.map((el) => {
          if (el.id === paymentModify.id) {
            return { ...el, ...paymentModify };
          }

          return el;
        })
      );
      setStatusPayment('');

      toast({
        title: 'El pago se actualizó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePayment = async (values, action) => {
    try {
      setLoading(true);

      await xhr.post(`/history-payments`, {
        data: {
          status: 'deleted',
          value: payment.amount,
          method: payment.method,
          state: payment.state,
          payment_date: payment.payment_date,
          student: payment.student.id,
          from_user: user.id,
          payment: payment.id,
          other_payments: payment.other_payments.map((el) => el.id),
          subjects: payment.subjects.map((el) => el.id),
          grade: payment.grade?.id,
          technical_career: payment.technical_career?.id,
          course: payment.course?.id,
          currency: payment.currency?.id,
          program_type: payment.program_type?.id,
          adviser: payment.adviser?.id,
          affiliate: payment.affiliate?.id,
        },
      });

      await xhr.delete(`/payments/${payment.id}`);

      setPayments(payments.filter((el) => el.id !== payment.id));
      setStatusPayment('');

      toast({
        title: 'El pago se eliminó correctamente!',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });

      setIsOpen(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Link href="/plataforma/administrador/">
        <a>Volver</a>
      </Link>
      <Box fontSize="25px" fontWeight="bold" my="15px">
        Historial de pagos
      </Box>
      <Flex w={'50%'} alignItems={'center'} gap={3}>
        <Input
          placeholder="Filtrar por ID del estudiante"
          value={idStudent}
          onChange={(e) => setIdStudent(e.target.value)}
          my="15px"
        />

        <Button colorScheme="blue" onClick={handleFilter}>
          Buscar
        </Button>
        <Button colorScheme="blue" onClick={handleCleanFilter}>
          Limpiar
        </Button>
      </Flex>
      <TableContainer>
        <Table margin="0px">
          <Thead bg="gray.200">
            <Tr>
              <Th>Estudiante</Th>
              <Th>Monto</Th>
              <Th>Status</Th>
              <Th>Fecha</Th>
              {!user?.school?.id ? <Th>Actualizar</Th> : ''}
            </Tr>
          </Thead>
          <Tbody>
            {fetching
              ? 'Cargando...'
              : payments.map((el, index) => {
                  return (
                    <Tr key={el.id}>
                      <Td>
                        <Link
                          to={`/plataforma/administrador/ver-estudiante/${el.student.id}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Box color="blue">{el.student.id}</Box>
                        </Link>
                      </Td>
                      <Td>{el.amount}</Td>
                      <Td>{el.status}</Td>
                      <Td>{dayjs(el.createdAt).format('YYYY-MM-DD')}</Td>
                      {!user?.school?.id ? (
                        <Td>
                          <Button
                            onClick={() => {
                              setPaymentsId(payment.id);
                              setPaymentModify(el);
                              setPayment(el);
                              setIsOpen(true);
                            }}
                            colorScheme="messenger"
                          >
                            <BiPencil />
                          </Button>
                          <Button
                            onClick={() => {
                              setPaymentsId(el.id);
                              setPayment(el);
                              setPaymentModify(el);
                              setIsOpenDelete(true);
                            }}
                            colorScheme="red"
                            ml="10px"
                          >
                            <BiTrash />
                          </Button>
                        </Td>
                      ) : (
                        ''
                      )}
                    </Tr>
                  );
                })}
          </Tbody>
        </Table>
      </TableContainer>
      <BasicModal
        onClose={() => {
          setStatusPayment('');
          setPayment({});
          setPaymentModify({});
        }}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        size={'lg'}
      >
        <Box>
          <Box>
            <Box fontWeight="bold" fontSize="20px" pb="10px">
              Actualizar el pago del estudiante {payment.student?.id}
            </Box>
            <hr />
          </Box>
          <Box mt="10px">Monto:</Box>
          <Input
            placeholder="Monto *"
            value={paymentModify.amount}
            onChange={(e) =>
              setPaymentModify({
                ...paymentModify,
                amount: e.target.value,
              })
            }
            my="5px"
          />

          <Box mt="10px">Descripción:</Box>
          <Input
            placeholder="Descripción *"
            value={paymentModify.description}
            onChange={(e) =>
              setPaymentModify({
                ...paymentModify,
                description: e.target.value,
              })
            }
            my="5px"
          />

          <Box mt="10px">Fecha: {dayjs(paymentModify.payment_date).format('DD/MM/YYYY')}</Box>
          <Input
            placeholder="Fecha *"
            value={paymentModify.payment_date}
            onChange={(e) =>
              setPaymentModify({
                ...paymentModify,
                payment_date: e.target.value,
              })
            }
            my="5px"
            type="date"
          />

          <Box mt="10px">Método:</Box>

          <Select
            mt="15px"
            name="status"
            color="black"
            onChange={(e) =>
              setPaymentModify({
                ...paymentModify,
                method: e.target.value,
              })
            }
            value={paymentModify.method}
          >
            <option value="payu">PayU</option>
            <option value="bancolombia">Bancolombia</option>
            <option value="efecty">Efecty</option>
            <option value="western_union">Western Union</option>
            <option value="stripe">Stripe</option>
            <option value="bitcoins">Bitcoins</option>
            <option value="paypal">Paypal</option>
            <option value="addi">Addi</option>
            <option value="wompi">Wompi</option>
            <option value="sede_bucaramanga">Sede Bucaramanga</option>
            <option value="sede_turbaco">Sede Turbaco</option>
            <option value="sede_arjona">Sede Arjona</option>
          </Select>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginTop="15px"
            width="100%"
            isLoading={loading}
            disabled={loading}
            onClick={handlePayment}
          >
            Actualizar
          </Button>
        </Box>
      </BasicModal>

      <BasicModal
        onClose={() => {
          setStatusPayment('');
          setPayment({});
          setPaymentModify({});
        }}
        isOpen={isOpenDelete}
        setIsOpen={setIsOpenDelete}
        size={'lg'}
      >
        <Box>
          <Box>
            <Box fontWeight="bold" fontSize="20px" pb="10px">
              Estás seguro que deseas eliminar este pago del estudiante {payment.student?.id}?
            </Box>
            <hr />
          </Box>

          <Button
            type="submit"
            colorScheme="#574feb"
            bg="#574feb"
            marginTop="15px"
            width="100%"
            isLoading={loading}
            disabled={loading}
            onClick={handleDeletePayment}
          >
            Eliminar
          </Button>
        </Box>
      </BasicModal>
    </>
  );
}
