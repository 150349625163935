import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Text, Select, Box, Button } from '@chakra-ui/react';
import xhr from 'xhr';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import useAuth from 'hooks/useAuth';

function LiveClassAdmin() {
  const navigate = useNavigate();
  const [programTypes, setProgramsTypes] = React.useState([]);

  const [semester, setSemester] = React.useState([]);
  const [groups, setGroups] = React.useState([]);
  const [programs, setPrograms] = React.useState([]);
  const [subjects, setSubjects] = React.useState([]);
  const [currentProgramType, setCurrentProgramType] = React.useState('');
  const [type, setType] = React.useState('');
  const [currentProgram, setCurrentProgram] = React.useState('');
  const [course, setCourse] = React.useState('');

  const { user } = useAuth();

  const [fetching, isFetching] = React.useState(true);

  const getPrograms = async (event) => {
    const programType = programTypes.find((el) => el.slug === event.target.value);

    if (programType) {
      const programsResponse = await xhr(
        `/${programType.type.replace('_', '-')}s?filters[program_type][id][$eq]=${
          programType.id
        }&filters[active][$eq]=true&pagination[limit]=-1&populate=*`
      );

      setPrograms(
        programsResponse.data.data.map((el) => {
          return { ...el, label: el.name, value: el.id };
        })
      );

      setCurrentProgramType(programType);
    }
  };

  React.useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const res = await xhr(`/program-types?filters[active][$eq]=true`);
        if (user?.school) {
          setProgramsTypes(
            res.data.data
              .filter((el) => el.id === 4 || el.id === 5)
              .map((el) => {
                return { ...el, label: el.name, value: el.slug };
              })
          );
        } else {
          setProgramsTypes(
            res.data.data.map((el) => {
              return { ...el, label: el.name, value: el.slug };
            })
          );
        }
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getProgramTypes();
  }, []);

  React.useEffect(() => {
    const getSemester = async () => {
      try {
        const res = await xhr(`/semesters`);
        setSemester(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getSemester();
  }, []);

  React.useEffect(() => {
    const getGroups = async () => {
      try {
        let res;
        if (user?.school) {
          res = await xhr(
            `/class-groups?filters[${currentProgramType.type}][id][$eq]=${currentProgram}&filters[school][id][$eq]=${user?.school?.id}&pagination[limit]=-1`
          );
        } else {
          res = await xhr(
            `/class-groups?filters[${currentProgramType.type}][id][$eq]=${currentProgram}&pagination[limit]=-1`
          );
        }

        setGroups(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    if (currentProgramType) getGroups();
  }, [currentProgram]);

  React.useEffect(() => {
    (async () => {
      if (currentProgram) {
        if (currentProgramType.type === 'course') {
        }
        const res = await xhr(
          `/subjects?filters[${programs[0].program_type.type}][id][$eq]=${currentProgram}&sort[0]=period:asc&pagination[limit]=-1`
        );
        setSubjects(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.id };
          })
        );
        setType(programs[0].program_type.type);
      }
    })();
  }, [currentProgram]);

  const BasicInfoSchema = Yup.object().shape({
    program_type: Yup.string().required('Requerido'),
    program: Yup.string().required('Requerido'),
  });

  const handleFilter = async (values, action) => {
    let url = `/plataforma/administrador/clases-en-vivo/subir-clases?program_type=${values.program_type}&program=${
      values.program
    }&type=${type}${values.semester && `&semester=${values.semester}`}${values.period && `&period=${values.period}`}`;

    if (values.group) url = url + `&group=${values.group}`;
    if (values.subject) url = url + `&subject=${values.subject}`;
    if (currentProgramType.type === 'course')
      url = url + `&subject=${programs.find((el) => el.id === currentProgram).subject.id}`;

    navigate(url);
  };

  return (
    <>
      <Box w="50%">
        <Text fontSize="28px" fontWeight="bold">
          Buscar
        </Text>
        <Box marginTop="20px">
          <Formik
            initialValues={{
              program_type: '',
              program: '',
              period: '',
              semester: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleFilter}
          >
            {(data) => {
              return (
                <Form>
                  <Box mb="7px">Tipo de programa </Box>
                  <Field as={Select} name={'program_type'} placeholder="Seleccionar" onChangeCapture={getPrograms}>
                    {programTypes.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name={'program_type'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>
                  <Box my="7px">Programa </Box>

                  <Field
                    name="program"
                    label="Programa"
                    as={Select}
                    placeholder="Seleccionar"
                    onChangeCapture={(event) => setCurrentProgram(Number(event.target.value))}
                  >
                    {programs.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name={'program'}>
                    {(msg) => <div style={{ color: 'red', fontSize: 13 }}>{msg}</div>}
                  </ErrorMessage>

                  <Box my="7px">Grupo </Box>
                  <Field name="group" as={Select} placeholder="Seleccionar">
                    {groups.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>

                  {currentProgramType.type !== 'course' && (
                    <>
                      <Box my="7px">Materia </Box>
                      <Field name="subject" as={Select} placeholder="Seleccionar">
                        {subjects.map((el, index) => (
                          <option key={el.value} value={el.value}>
                            {el.label}
                          </option>
                        ))}
                      </Field>
                    </>
                  )}

                  <Box mt="10px" fontWeight="500">
                    Datos opcionales
                  </Box>
                  <br />
                  <Field name="period" placeholder="Periodo" as={Select}>
                    <option value="P1">Periodo 1</option>
                    <option value="P2">Periodo 2</option>
                    <option value="P3">Periodo 3</option>
                    <option value="P4">Periodo 4</option>
                  </Field>
                  <br />
                  <Field name="semester" placeholder="Semestre" as={Select}>
                    {semester.map((el, index) => (
                      <option key={el.value} value={el.value}>
                        {el.label}
                      </option>
                    ))}
                  </Field>
                  <br />

                  <Button type="submit" colorScheme="#574feb" bg="#574feb" width="140px">
                    Buscar
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
    </>
  );
}

export default LiveClassAdmin;
