import React from 'react';
import {
  Text,
  Box,
  Grid,
  GridItem,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import xhr from 'xhr';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import ReplayModal from './ReplayModal';
import FormLiveClasses from './formLiveClasses';
import BasicModal from 'components/modal/Modal';
import DuplicateClasses from './DuplicateClasses';
import useAuth from 'hooks/useAuth';

function SubirClases() {
  let [searchParams] = useSearchParams();
  const [liveClasses, setLiveClasses] = React.useState([]);
  const [semester, setSemester] = React.useState([]);
  const [live, setLive] = React.useState([]);
  const [fetching, isFetching] = React.useState(true);
  const [reload, setReload] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpen2, setIsOpen2] = React.useState(false);

  const { user } = useAuth();

  const subject = searchParams.get('subject');
  const type = searchParams.get('type');
  const classGroup = searchParams.get('class_group');
  const program = searchParams.get('program');

  React.useEffect(() => {
    const getLiveClasses = async () => {
      try {
        let res;

        if (user?.school) {
          res = await xhr(
            `/live-classes?filters[subject][id][$eq]=${searchParams.get('subject')}&filters[school][id][$eq]=${
              user?.school?.id
            }&sort[0]=start_date:DESC&populate=*&pagination[limit]=-1`
          );
        } else {
          res = await xhr(
            `/live-classes?filters[subject][id][$eq]=${searchParams.get(
              'subject'
            )}&sort[0]=start_date:DESC&populate=*&pagination[limit]=-1`
          );
        }

        setLiveClasses(res.data.data);
        setReload(false);
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getLiveClasses();
  }, [reload]);

  React.useEffect(() => {
    const getSemester = async () => {
      try {
        const res = await xhr(`/semesters?sort[0]=id:DESC`);
        setSemester(
          res.data.data.map((el) => {
            return { ...el, label: el.name, value: el.name };
          })
        );
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getSemester();
  }, []);

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="3xl"
        onClose={() => {
          setIsOpen(false);
          setLive('');
          setReload(false);
        }}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ReplayModal
              setLive={setLive}
              live={live}
              semester={semester}
              reload={reload}
              setReload={setReload}
              setIsOpen={setIsOpen}
              user={user}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpen2}
        size="3xl"
        onClose={() => {
          setIsOpen2(false);
        }}
        motionPreset="scale"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <DuplicateClasses
              setLive={setLive}
              subject={subject}
              type={type}
              classGroup={classGroup}
              program={program}
              setReload={setReload}
              setIsOpen={setIsOpen2}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem>
          <Box border="1px solid #cac9c9" borderRadius="10px" p="15px">
            <Text fontSize="28px" fontWeight="bold">
              Crear clase en vivo (
              {liveClasses[0]?.course ? liveClasses[0]?.course?.name : liveClasses[0]?.subject?.name})
            </Text>
            <Box marginTop="20px">
              <FormLiveClasses
                live={live}
                semester={semester}
                title={liveClasses[0]?.course ? liveClasses[0]?.course.name : liveClasses[0]?.subject?.name}
                setReload={setReload}
                reload={reload}
                user={user}
              />
            </Box>
          </Box>
        </GridItem>

        <GridItem spreadOffset="left" border="1px solid #cac9c9" borderRadius="10px" p="15px">
          <Text fontSize="28px" fontWeight="bold">
            Clases en vivo creadas
          </Text>
          {user?.school ? '' : <Button onClick={() => setIsOpen2(true)}>Duplicar clases</Button>}

          <TableContainer>
            <Table marginTop="35px">
              <Thead bg="gray.200">
                <Tr>
                  <Th>Titulo</Th>
                  <Th textAlign="right">Fecha</Th>
                </Tr>
              </Thead>
              <Tbody>
                {liveClasses.map((live) => {
                  return (
                    <Tr>
                      <Td>
                        <Button
                          onClick={() => {
                            setIsOpen(true);
                            setLive(live);
                          }}
                        >
                          {live.title}
                        </Button>
                      </Td>
                      <Td textAlign="right">{dayjs(live.start_date).format('MMM-DD-YYYY hh:mm A')} </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </GridItem>
      </Grid>
    </>
  );
}

export default SubirClases;
