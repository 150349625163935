import xhr from 'xhr';

export default async function getInfoStudent(data) {
  const user = data.user;
  if (data.id_est) {
    let dataStudent;

    if (user.role?.name === 'School') {
      dataStudent = await xhr(
        `/students?filters[id][$eq]=${data.id_est}&filters[schoolFrom][id][$eq]=${user.school?.id}&populate=deep,3`
      );

      if (dataStudent.data.data.length === 0) return { error: 'No se encontró el estudiante' };
    } else {
      dataStudent = await xhr(`/students/${data.id_est}?populate=deep,3`);
    }

    const resProgramTypes = await xhr(`/program-types/student/${data.id_est}`);
    const resParent = await xhr(`/parents?filters[students][id][$in]=${data.id_est}&populate=*`);
    const advisersData = await xhr(`/advisers?populate=deep,3`);
    const badges = await xhr(`/student-badges?filters[student][id][$eq]=${data.id_est}&populate=deep,3`);
    const affiliate = await xhr(`/affiliate-records?filters[student][id][$eq]=${data.id_est}&populate=deep,3`);

    const advisers = advisersData.data.data.map((el) => ({
      ...el,
      value: el.id,
      label: `${el.user?.first_name} ${el.user?.last_name}`,
    }));
    return {
      programTypes: resProgramTypes.data,
      estudiante: dataStudent.data.data.length ? dataStudent.data.data[0] : dataStudent.data.data,
      badges: badges.data.data,
      parent: resParent.data.data[0]?.user,
      advisers: advisers,
      affiliate: affiliate.data.data[0]?.affiliate,
    };
  }
}
