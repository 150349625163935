import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
  Input,
  Text,
} from '@chakra-ui/react';
import globals from 'styles/globals.module.css';
import { useDropzone } from 'react-dropzone';

import xhr from 'xhr';

import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import dayjs from 'dayjs';

import useSWR from 'swr';
import useAuth from 'hooks/useAuth';
import getStudentHistory from 'api/administrador/student/student-history';
import { BiCloudUpload } from 'react-icons/bi';
import getStudentObserver from 'api/administrador/student/student-observer';
import { capitalize } from 'lodash';

export default function ObservadorTable() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [file, setFile] = React.useState(null);
  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [loading, setLoading] = useState(false);
  const params = useParams();
  const id_est = params.id;

  const { user } = useAuth();

  const BasicInfoSchema = Yup.object().shape({
    detail: Yup.string().required('Requerido'),
  });

  const toasts = useToast();

  const { data: history, mutate } = useSWR({ type: 'student-observer', id: id_est }, getStudentObserver, {
    revalidateOnFocus: false,
  });

  return (
    <>
      {!history ? (
        <Box display="flex" justifyContent="center" alignItems="center" alignContent="center">
          <Spinner />
        </Box>
      ) : (
        <>
          {history.length > 0 ? (
            <TableContainer maxHeight="600px" overflowY="auto">
              <Table variant="striped" colorScheme="facebook">
                <Thead>
                  <Tr>
                    <Th>Razón</Th>
                    <Th>Llamado de atención</Th>
                    <Th>Docente</Th>
                    <Th>Fecha</Th>
                    <Th textAlign="right">Firma Observador</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {history.map((requi) => {
                    return (
                      <Tr key={requi.id}>
                        <Td>
                          <Box
                            whiteSpace="break-spaces"
                            width="180px"
                            dangerouslySetInnerHTML={{ __html: requi.razon }}
                          />
                        </Td>
                        <Td>{requi.llamado_atencion ? 'Si' : 'No'}</Td>
                        <Td>{`${capitalize(requi.from_teacher.user.first_name)} ${capitalize(
                          requi.from_teacher.user.last_name
                        )}`}</Td>
                        <Td> {dayjs(requi.createdAt).format('DD-MM-YYYY hh:mm A')}</Td>
                        <Td textAlign="right">{requi.final_observador ? 'Si' : 'No'}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <Box padding="20px">este estudiante aún no tiene historial</Box>
          )}
        </>
      )}
    </>
  );
}
