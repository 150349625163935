import xhr from 'xhr';

export default async function getStudentsAdmin(data) {
  const user = data.user;
  if (user.role.name === 'School') {
    const res = await xhr(
      `/students?pagination[limit]=10&filters[schoolFrom][id][$eq]=${user.school.id}&populate=deep,2&&sort[0]=id:desc`
    );
    return res.data.data;
  } else {
    const res = await xhr(`/students?pagination[limit]=10&populate=deep,2&sort[0]=id:desc`);
    return res.data.data;
  }
}
