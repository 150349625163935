import React from 'react';
import { Formik, Form, Field } from 'formik';
import {
  Input,
  Text,
  Box,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Button,
  Icon,
  Link,
} from '@chakra-ui/react';

import useAuth from 'hooks/useAuth';
import { useDropzone } from 'react-dropzone';

import xhr from 'xhr';

import { convertToRaw } from 'draft-js';
import * as Yup from 'yup';

import Editor from 'components/editor/index';
import { BiCloudUpload } from 'react-icons/bi';

var draftToHtml;
var htmlToDraft;

function CrearPizarron() {
  const { user } = useAuth();

  const [file, setFile] = React.useState(null);
  const [editorState, setEditorState] = React.useState('');

  const onDrop = React.useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [fetching, isFetching] = React.useState(true);
  const [loading, setLoading] = React.useState(false);

  const toasts = useToast();

  const [posts, setPosts] = React.useState([]);

  React.useEffect(() => {
    const getDraft = async () => {
      draftToHtml = await import('draftjs-to-html');
      htmlToDraft = await import('html-to-draftjs');
    };

    getDraft();
  }, []);

  React.useEffect(() => {
    const getPosts = async () => {
      try {
        if (user?.school?.id) {
          const res3 = await xhr(
            `/institutional-posts?filters[school][id][$eq]=${user?.school?.id}&sort[0]=createdAt:DESC`
          );
          setPosts(res3.data.data);
        } else {
          const res3 = await xhr(`/institutional-posts?sort[0]=createdAt:DESC`);
          setPosts(res3.data.data);
        }
      } catch (error) {
      } finally {
        isFetching(false);
      }
    };
    getPosts();
  }, []);

  const handleEditorChange = (value) => {
    setEditorState(value);
  };

  const BasicInfoSchema = Yup.object().shape({
    title: Yup.string().required('Requerido'),
  });
  const handleTopic2 = async (values, action) => {
    try {
      setLoading(true);
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const content = draftToHtml.default(rawContentState);

      if (!content || (rawContentState.blocks[0] && rawContentState.blocks[0].text === ''))
        return window.alert('No debes enviar el campo de texto vacío');

      const response = await xhr.post(`/institutional-posts`, {
        data: {
          ...values,
          content,
          user: user.id,
          school: user?.school?.id || null,
          global: true,
        },
      });

      setPosts([response.data.data, ...posts]);

      toasts({
        title: 'El pizarrón se creo correctamente',
        status: 'success',
        duration: 2000,
        isClosable: true,
      });
      setEditorState('');

      action.resetForm();
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h1>Pizarrón de noticias</h1>
      <Grid templateColumns="repeat(2, 1fr)" gap={5}>
        <GridItem colSpan={1}>
          <Formik
            initialValues={{
              title: '',
            }}
            validationSchema={BasicInfoSchema}
            onSubmit={handleTopic2}
          >
            <Form>
              <Box fontWeight="500">Titulo</Box>
              <Field marginTop="10px" name="title" as={Input} />

              <Box marginTop="10px">
                <Text fontWeight="500" my="5px">
                  Contenido
                </Text>
                <Box background="white">
                  <Editor editorState={editorState} handleEditorChange={handleEditorChange} />
                </Box>
              </Box>

              {file && (
                <Link fontWeight="500" marginTop="sm" href={file.url} target="_blank">
                  {file.name}
                </Link>
              )}
              <br />
              <Button
                isDisabled={loading}
                isLoading={loading}
                type="submit"
                colorScheme="messenger"
                marginY="sm"
                marginX="sm"
                width="140px"
              >
                Crear
              </Button>
            </Form>
          </Formik>
        </GridItem>

        <GridItem colSpan={1} marginTop="20p1">
          {fetching
            ? 'cargando'
            : posts[0] && (
                <Table isResponsive>
                  <Thead bg="gray.200">
                    <Tr>
                      <Th>Pizarrón Creados</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Box height="400px" overflowY="scroll">
                      {posts.map((post) => {
                        return (
                          <Tr>
                            <Td>{post.title}</Td>
                          </Tr>
                        );
                      })}
                    </Box>
                  </Tbody>
                </Table>
              )}
        </GridItem>
      </Grid>
    </>
  );
}

export default CrearPizarron;
